function WorkItems({ data, heightOfMonth, startYear, fonts_p }) {
  const workCols = data.map((item) => {
    if (item.type_of_expirience === 'work') {
      const { year_end: yearEnd, month_end: monthEnd, year, month } = item;

      let height = ((yearEnd - year) * 12 - (month - monthEnd)) * heightOfMonth;
      let offsetTop = ((year - startYear) * 12 - (0 - item.month)) * heightOfMonth;
      if (item.now_expirience) {
        let currentDate = new Date();
        height = ((currentDate.getFullYear() - year) * 12 - (month - currentDate.getMonth())) * heightOfMonth;
        offsetTop = ((year - startYear) * 12 - (0 - month)) * heightOfMonth;
      }

      const css = {
        height: height + 'px',
        top: offsetTop + 'px',
        fontFamily: fonts_p[0].font_p_steps,
        fontWeight: fonts_p[1].font_p_steps
      };

      if (height <= heightOfMonth * 3) {
        css.left = '35px';
        css.width = '86%';
      }

      return <p style={css} key={item.id.toString()} className={`expirience-box ${item.now_expirience}`}>{item.name_of_expirience}</p>
    }
  });
  return workCols;
}

export default WorkItems;