import React from 'react';
import { Container } from 'react-bootstrap';
import './preloader.sass';

function Preloader() {
	return (
		<Container fluid className="preloader">
			<div className='example'>
				<div className='block'>
					<div className='item'></div>
					<div className='item'></div>
					<div className='item'></div>
					<div className='item'></div>
					<div className='item'></div>
					<div className='item'></div>
					<div className='item'></div>
					<div className='item'></div>
				</div>
			</div>
		</Container>
	);
}

export default Preloader;
