import React from "react";
import Body from './components/layout/Body';
import './global-sass/global.sass';

class App extends React.Component {
  render() {
    return (
      <Body />
    )
  }
}

export default App;
