import { React } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import { Nav, Container } from "react-bootstrap";
import './header.sass';

function Header({ firstscreenData, headers, fonts_p }) {
  const font_span_name_header_css={
    fontFamily: fonts_p[0].font_span_name_header,
    fontWeight: fonts_p[1].font_span_name_header
  }
  const font_span_profession_header_css={
    fontFamily: fonts_p[0].font_span_profession_header,
    fontWeight: fonts_p[1].font_span_profession_header
  }
  const font_menu_items_css={
    fontFamily: fonts_p[0].font_menu_items,
    fontWeight: fonts_p[1].font_menu_items
  }

  return (
    <section className="header p-0">
      <Container>
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Brand href="#">
            <span style={font_span_name_header_css} className="full-name">{firstscreenData[0].fullName}</span>
            <span style={font_span_profession_header_css} className="profession">{firstscreenData[0].profession}</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav style={font_menu_items_css}>              
              <Nav.Link href={`#${headers[3].link}`} key={headers[3].link} >{headers[3].h2About}</Nav.Link>              
              <Nav.Link href={`#${headers[6].link}`} key={headers[6].link} >{headers[6].h2About}</Nav.Link>
              <Nav.Link href={`#${headers[0].link}`} key={headers[0].link} >{headers[0].h2About}</Nav.Link>
              <Nav.Link href={`#${headers[2].link}`} key={headers[2].link} >{headers[2].h2About}</Nav.Link>
              <Nav.Link href={`#${headers[4].link}`} key={headers[4].link} >{headers[4].h2About}</Nav.Link>
              <Nav.Link href={`#${headers[5].link}`} key={headers[5].link} >{headers[5].h2About}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </section>

  )
}

export default Header;