import React from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Gallery from "./Gallery";
import { Col, Row } from "react-bootstrap";
import { REACT_APP_API_URL } from '../../../../../constants';

function ModalFramePortfolio({ project, fonts_headers, fonts_p }) {
  const font_h3_css={
    fontFamily: fonts_headers[0].h3,
    fontWeight: fonts_headers[1].h3
  }
  const font_main_css={
    fontFamily: fonts_p[0].font_main_p,
    fontWeight: fonts_p[1].font_main_p
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className={`shadow-box animate__animated animate__fadeInUp animate__delay-1s`}>
        <img
          className="w-100"
          onClick={handleShow}
          alt=""
          src={`${REACT_APP_API_URL}/assets/${project.cover.id}`}
        />
      </div>

      <Modal 
        show={show} 
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="align-items-start bg-gray border-radius" closeButton>
          <Row className="align-items-center">
            <Col md={8}>
            <img
              onClick={handleShow}
              alt=""
              src={`${REACT_APP_API_URL}/assets/${project.cover.id}`}
            />
            </Col>
            <Col md={4}>
              <Modal.Title style={font_h3_css}>{project.title}</Modal.Title>
              <span style={font_main_css}>Площадь: {project.scale} м<sup>2</sup></span>
            </Col>
          </Row>
        </Modal.Header>        
        <Gallery project={project} />
      </Modal>
    </>
  );
}

export default ModalFramePortfolio;
