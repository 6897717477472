import React from "react";
import { Container, Col, Row } from 'react-bootstrap';
import './expirience.sass';
import YearsList from "./YearsList";
import EducationItems from "./EducationItems";
import WorkItems from "./WorkItems";

function ExperienceTimelineVidget({ expirienceData, headers, expirienceHeaders, fonts_p, fonts_headers }) {
  const startYear = 2013;
  const heightOfMonth = 5;
  const font_h2_css={
    fontFamily: fonts_headers[0].h2,
    fontWeight: fonts_headers[1].h2
  }
  const font_h3_css={
    fontFamily: fonts_headers[0].h3,
    fontWeight: fonts_headers[1].h3
  }
  const font_span_years_css={
    fontFamily: fonts_p[0].font_span_years,
    fontWeight: fonts_p[1].font_span_years
  }

  return (
    <>
    <section className="bg-gray expirience-section" id={headers[2].link}>
      <Container>
        <h2 style={font_h2_css}>{headers[2].h2About}</h2>
        <Row>
          <Col xs={12} className="d-md-none">
            <h3 style={font_h3_css}>{expirienceHeaders[0].education}</h3>
          </Col>
          <Col xs={2} md={1} className="year-col" >
            <YearsList style={font_span_years_css} startYear={startYear} heightOfMonth={heightOfMonth}/>
          </Col>
          <Col xs={10} md={5} className="position-relative">
            <EducationItems fonts_p={fonts_p} data={expirienceData} startYear={startYear} heightOfMonth={heightOfMonth}/>
          </Col>
          <Col xs={12} className="d-md-none">
            <h3 style={font_h3_css}>{expirienceHeaders[0].expirience}</h3>
          </Col>
          <Col xs={10} md={5} className="position-relative">
            <WorkItems fonts_p={fonts_p} data={expirienceData} startYear={startYear} heightOfMonth={heightOfMonth}/>
          </Col>
          <Col xs={2} md={1} className="year-col">
            <YearsList style={font_span_years_css} startYear={startYear} heightOfMonth={heightOfMonth}/>
          </Col>
        </Row>
      </Container>
    </section>
    </>
	)
}

export default ExperienceTimelineVidget;
