import { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import "./ToTopButton.sass";

const ToTopButton = () => {
  // The back-to-top button is hidden at the beginning
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  return (
    <>
			<Fade delay={100} bottom>
				{showButton && (
					<button onClick={scrollToTop} className="back-to-top">
						&#8593;
					</button>
				)}
			</Fade>
    </>
  );
};

export default ToTopButton;