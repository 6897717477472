import { React } from "react";
import { Container } from 'react-bootstrap';
import './work-steps.sass';
import WorkStep from "./WorkStep";

function StepsScreen({ workStepsData, headers, fonts_p, fonts_headers }) {
  const font_h2_css={
    fontFamily: fonts_headers[0].h2,
    fontWeight: fonts_headers[1].h2
  }

  const stepItems = workStepsData.map((item) => {
    return(
      <WorkStep item={item} key={item.id} fonts_p={fonts_p} fonts_headers={fonts_headers}/>
    )         
  });

return (
  <>
    <section id={headers[3].link} className="bg-gray">
      <Container className="position-relative" >
        <h2 style={font_h2_css}>{headers[3].h2About}</h2>
        {stepItems} 
      </Container>
    </section>
  </>
  )
}

export default StepsScreen;