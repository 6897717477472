import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './contacts.sass';
import Slide from 'react-reveal/Slide';
import { REACT_APP_API_URL } from '../../../../constants';

function ContactsScreen({ contactsData, callToActionData, headers, fonts_p, fonts_headers }) {
  const fonts_p_css={
    fontFamily: fonts_p[0].font_p_contacts,
    fontWeight: fonts_p[1].font_p_contacts
  }
  const fonts_link_css={
    fontFamily: fonts_p[0].font_span_contact_link,
    fontWeight: fonts_p[1].font_span_contact_link
  }
  const font_h2_css={
    fontFamily: fonts_headers[0].h2,
    fontWeight: fonts_headers[1].h2
  }
  const font_h3_css={
    fontFamily: fonts_headers[0].h3,
    fontWeight: fonts_headers[1].h3
  }

  const contactItems = contactsData.map((item, index) => 
    <Col key={item.id.toString()}  md={4} lg={2} className="col-6 mb-5 mb-lg-0 about-box text-center">
      <Slide bottom delay={`${index}00`}>
        <a href={item.link} target="_blank">
          <span className="about-icon m-auto">    
            <img 
              alt="" 
              src={`${REACT_APP_API_URL}/assets/${item.icon.id}?download`} 
            />
          </span>
          <h3 style={font_h3_css}>{item.header}</h3>
          <p  style={fonts_p_css}>{item.contact}</p>
          <span style={fonts_link_css} className="link">{item.button_text}</span>
        </a>
      </Slide>
    </Col>
  );

  

  return (
    <>
      <section id={headers[5].link} className="bg-gray">
        <Container>
          <h2 style={font_h2_css} className="mb-1">{headers[5].h2About}</h2>          
          <p style={fonts_p_css} className="text-center mb-5">{callToActionData[0].call_to_action}</p>
          <Row className="justify-content-center">{contactItems}</Row>
        </Container>
      </section>
    </>
  )
}

export default ContactsScreen;