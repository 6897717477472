function YearsList(props) {
  let currentYear = new Date().getFullYear();
  let arrayOfYears = [];
  const startYear = props.startYear;
  const style = props.style;

  for(let i = startYear; i <= currentYear; i++) {
    arrayOfYears.push(i);
  }
  
  const yearsList = arrayOfYears.map((item) => 
    <span style={style} key={item.toString()} className="span-year">{item}</span>
  );
  return yearsList;
}

export default YearsList;