import { React } from "react";
import { useQuery, gql } from "@apollo/client";

import FirstScreen from "./sections/FirstScreen";
import AboutUsScreen from "./sections/AboutUsScreen";
import NumbersScreen from "./sections/NumbersScreen";
import StepsScreen from "./sections/StepsScreen";
import ExperienceTimelineVidget from "./sections/ExpirienceScreen/ExperienceTimelineVidget";
import PriceScreen from "./sections/PriceScreen";
import ContactsScreen from "./sections/ContactsScreen";
import PortfolioScreen from "./sections/PortfolioScreen/PortfolioScreen";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import Preloader from "../../layout/Preloader";

const DATA_QUERY = gql`
	query {    
    headers {
      h2About
      link
    }
    firstscreen {
			id
      H1
			offer
      buttonText
      elaPhoto {
        id
      }
      elaPhotoMoile {
        id
      }
      fullName
      profession
		}
    work_steps {
      h2
      id
      step
      text
      illustration_step {
        id
      }
    }
    portfolio {
      cover {
        id
      }
      scale
      id
      title
      project_files {
        id
        directus_files_id {
          id
        }
      }
      project_files_mini {
        id
        directus_files_id {
          id
        }
      }
    }
    aboutMe(sort: "number") {
      id
      content
      h3
      icon {
        id
      }
    }
    numbers {
      id
      number
      text
    }
    education {
      type_of_expirience
      name_of_expirience
      now_expirience
      year
      year_end
      month_end
      month
      id
    }
    expirience_headers {
      education
      expirience
    }
    price_list {
      button_text
      id
      period
      price
      scale
      units
    }
    price_condition {
      id
      header_1
      header_2
      price_decrease
      price_increase
    }
    contacts {
      contact
      header
      button_text
      link
      id
      icon {
        id
      }
    }
    call_to_action {
      call_to_action
    }
    fonts {
      font_link
    }
    fonts_p {
      font_button
      font_main_p
      font_menu_items
      font_p_about
      font_p_contacts
      font_p_expirience
      font_p_firstscreen
      font_p_numbers
      font_p_prices
      font_p_prices_notes
      font_p_steps
      font_steps
      font_span_contact_link
      font_span_name_firstscreen
      font_span_name_header
      font_span_numbers
      font_span_price
      font_span_price_note
      font_span_profession_firstscreen
      font_span_profession_header
      font_span_years
    }
    fonts_headers {
      h1
      h2
      h3
    }
	}
`;

function LandingPage() {
  const { loading, error, data } = useQuery(DATA_QUERY);

  if (loading) return <div><Preloader /></div>;
  if (error) return <span>Ошибка...</span>;

  const portfolioData = data.portfolio;
  const headers = data.headers;
	const firstscreenData = data.firstscreen;
  const workStepsData = data.work_steps;
	const aboutMeData = data.aboutMe;
	const numbersData = data.numbers;
	const expirienceData = data.education;
  const expirienceHeaders = data.expirience_headers;
  const priceListData = data.price_list;
  const priceConditionData = data.price_condition;
  const contactsData = data.contacts;
  const callToActionData = data.call_to_action;
  const fonts = data.fonts;
  const fonts_p = data.fonts_p;
  const fonts_headers = data.fonts_headers;
  const font_main_css={
    fontFamily: fonts_p[0].font_main_p,
    fontWeight: fonts_p[1].font_main_p
  }
  return(
    <>
      <div dangerouslySetInnerHTML={{__html: fonts[0].font_link}} />
      <div style={font_main_css}>
        <Header firstscreenData={firstscreenData} headers={headers} fonts_p={fonts_p} />
        <FirstScreen firstscreenData={firstscreenData} contactsData={contactsData} fonts={fonts} fonts_p={fonts_p} fonts_headers={fonts_headers}/>
        <StepsScreen workStepsData={workStepsData} headers={headers} fonts_p={fonts_p} fonts_headers={fonts_headers} />
        <PortfolioScreen portfolioData={portfolioData} headers={headers} fonts_p={fonts_p} fonts_headers={fonts_headers} />
        <AboutUsScreen aboutMeData={aboutMeData} headers={headers} fonts_p={fonts_p} fonts_headers={fonts_headers} />
        <NumbersScreen numbersData={numbersData} headers={headers} fonts_p={fonts_p} fonts_headers={fonts_headers} />
        <ExperienceTimelineVidget expirienceData={expirienceData} expirienceHeaders={expirienceHeaders} headers={headers} fonts_p={fonts_p} fonts_headers={fonts_headers} />
        <PriceScreen priceListData={priceListData} priceConditionData={priceConditionData} headers={headers} fonts_p={fonts_p} fonts_headers={fonts_headers} />
        <ContactsScreen contactsData={contactsData} callToActionData={callToActionData} headers={headers} fonts_p={fonts_p} fonts_headers={fonts_headers} />
        <Footer firstscreenData={firstscreenData} contactsData={contactsData} fonts_p={fonts_p} />
      </div>
    </>
  )
}

export default LandingPage;