import React from "react";

export default function(text) {
  if (!text) { return ''; }

  return text.split("\n").reduce((acc, text, index) => {
    acc.push(<span key={`${text} ${index}`}>{ text }</span>, <br key={index} />);
    return acc;
  }, []);
}
