import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";
import './footer.sass';
import { REACT_APP_API_URL } from '../../constants';
import ToTopButton from "./ToTopButton";

function Footer({ firstscreenData, contactsData, fonts_p }) {
  const font_span_name_header_css={
    fontFamily: fonts_p[0].font_span_name_header,
    fontWeight: fonts_p[1].font_span_name_header
  }
  const font_span_profession_header_css={
    fontFamily: fonts_p[0].font_span_profession_header,
    fontWeight: fonts_p[1].font_span_profession_header
  }

  const contactItems = contactsData.map((item) => 
    <a href={item.link} target="_blank" key={item.id.toString()}>
      <li>
        <span className="about-icon">    
          <img 
            alt="" 
            src={`${REACT_APP_API_URL}/assets/${item.icon.id}?download`} 
          />
        </span>
      </li>
    </a>
  );

  return (
    <section className="footer">
      <Container>
          <Row>
            <Col md={6}>
              <span style={font_span_name_header_css} className="full-name">{firstscreenData[0].fullName}</span>
              <span style={font_span_profession_header_css} className="profession mb-md-0">{firstscreenData[0].profession}</span>
            </Col>
            <Col md={6} className="text-right align-self-center">
              <ul className="ul_contacts mb-0">{contactItems}</ul>
            </Col>
          </Row>
      </Container>
      <ToTopButton />
    </section>
  )
}

export default Footer;