import React from "react";
import { Button, Container, Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import './firstscreen.sass';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import { REACT_APP_API_URL } from '../../../../constants';

function FirstScreen({ firstscreenData, contactsData, fonts_p, fonts_headers }) {
  const contactItems = contactsData.map((item) => 
    <a href={item.link} target="_blank" key={item.id.toString()}>
      <li>
        <span className="about-icon">    
          <img 
            alt="" 
            src={`${REACT_APP_API_URL}/assets/${item.icon.id}?download`} 
          />
        </span>
      </li>
    </a>
  );

  const fonts_p_css={
    fontFamily: fonts_p[0].font_p_firstscreen,
    fontWeight: fonts_p[1].font_p_firstscreen
  }
  const font_h1_css={
    fontFamily: fonts_headers[0].h1,
    fontWeight: fonts_headers[1].h1
  }
  const font_button_css={
    fontFamily: fonts_p[0].font_button,
    fontWeight: fonts_p[1].font_button
  }
  const font_span_name_firstscreen_css={
    fontFamily: fonts_p[0].font_span_name_firstscreen,
    fontWeight: fonts_p[1].font_span_name_firstscreen
  }
  const font_span_profession_firstscreen_css={
    fontFamily: fonts_p[0].font_span_profession_firstscreen,
    fontWeight: fonts_p[1].font_span_profession_firstscreen
  }

  return (
    <Container fluid className="first-screen bg-with-lines">
      <Row className="align-items-center">
        <Col md={{ span: 6 }} lg={7} className="texts">
          <Fade bottom>
            <h1 style={font_h1_css} className="header-1 mb-lg-5"  dangerouslySetInnerHTML={{__html: firstscreenData[0].H1}}></h1>
            <p style={fonts_p_css} dangerouslySetInnerHTML={{__html: firstscreenData[0].offer}}></p>
            <a style={font_button_css} href="#steps"><Button size="lg" variant="outline-dark">{firstscreenData[0].buttonText}</Button></a>
          </Fade>
        </Col>
        <Col md={6} lg={4} className="img-wrapper d-none d-md-flex">
          <img className="ela-photo" alt="Эльвира Глазкова - специалист по чертежам для дизайн-проектов в Revit" src={`${REACT_APP_API_URL}/assets/${firstscreenData[0].elaPhoto.id}?download`} />
        </Col>
        <Col className="img-wrapper d-md-none">
          <img className="ela-photo" alt="Эльвира Глазкова - специалист по чертежам для дизайн-проектов в Revit" src={`${REACT_APP_API_URL}/assets/${firstscreenData[0].elaPhotoMoile.id}?download`} />
        </Col>
        <Col md={{ span: 6, offset: 1 }} className="margin-40 d-none d-md-block">       
          <div className="align-items-center text-end icons">
            <Slide bottom cascade> 
              <ul className="ul_contacts mb-0">{contactItems}</ul>
            </Slide>  
          </div>
        </Col>
        <Col md={5} className="name-box">
          <Fade delay={1000} bottom>
            <span style={font_span_name_firstscreen_css} className="full-name">{firstscreenData[0].fullName}</span><br />
          </Fade>
          <Fade delay={1500} bottom>
            <span style={font_span_profession_firstscreen_css} className="profession">{firstscreenData[0].profession}</span>
          </Fade>
        </Col>
        <Col md={{ span: 6, offset: 1 }} className="margin-40 d-md-none">       
          <div className="align-items-center icons">
            <Slide bottom cascade> 
              <ul className="ul_contacts mb-0">{contactItems}</ul>
            </Slide>  
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default FirstScreen;