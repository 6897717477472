import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './aboutus.sass';
import renderTextWithNewLines from '../../../../utils/render_text_with_newlines';
import { REACT_APP_API_URL } from '../../../../constants';

function AboutUsScreen({ aboutMeData, headers, fonts_p, fonts_headers }) { 
  const fonts_p_css={
    fontFamily: fonts_p[0].font_p_about,
    fontWeight: fonts_p[1].font_p_about
  }
  const font_h2_css={
    fontFamily: fonts_headers[0].h2,
    fontWeight: fonts_headers[1].h2
  }
  const font_h3_css={
    fontFamily: fonts_headers[0].h3,
    fontWeight: fonts_headers[1].h3
  }

  const aboutItems = aboutMeData.map((item) => 
    <Col key={item.id.toString()} md={6} lg={4} className="about-box">
      <span className="about-icon mx-auto">    
        <img 
          alt="" 
          src={`${REACT_APP_API_URL}/assets/${item.icon.id}?download`} 
        />
      </span>
      <h3 style={font_h3_css}>{item.h3}</h3>
      <p style={fonts_p_css} className="d-none d-md-block">{renderTextWithNewLines(item.content)}</p>
      <p style={fonts_p_css} className="d-md-none">{item.content}</p>
    </Col>
  );
  
	return (
    <>
    <section id={headers[0].link} className="bg-gray">
      <Container>
        <h2 style={font_h2_css}>{headers[0].h2About}</h2>
        <Row className="text-center">
          {aboutItems}
        </Row>
      </Container>
    </section>
    </>
	)
}

export default AboutUsScreen;