import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { REACT_APP_API_URL } from '../../../../../constants';

function Gallery({ project }) {
  const filesItems = project.project_files.map((item, index) => {
    return (
      <a
        key={item.id}
        className="gallery-item col-6 col-md-3"
        data-src={`${REACT_APP_API_URL}/assets/${item.directus_files_id.id}?download`}
      >
        <img
          className="img-responsive w-100"
          src={`${REACT_APP_API_URL}/assets/${project.project_files_mini[index].directus_files_id.id}?download`}
        />  
      </a>
    )
  });

  return (
    <div className="App">
      <LightGallery
        speed={500}
        plugins={[lgThumbnail, lgZoom]}
        elementClassNames="row"
        mode="lg-fade"
      >
        {filesItems}
      </LightGallery>
    </div>
  );
}

export default Gallery;
