import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './numbers.sass';
import Slide from 'react-reveal/Slide';

function NumbersScreen({ numbersData, headers, fonts_p, fonts_headers  }) {
  const fonts_p_css={
    fontFamily: fonts_p[0].font_p_expirience,
    fontWeight: fonts_p[1].font_p_expirience
  }
  const font_h2_css={
    fontFamily: fonts_headers[0].h2,
    fontWeight: fonts_headers[1].h2
  }
  const font_span_numbers_css={
    fontFamily: fonts_p[0].font_span_numbers,
    fontWeight: fonts_p[1].font_span_numbers
  }

  const aboutItems = numbersData.map((item, index) => 
    <Col xs={6} lg={3} key={item.id.toString()}>
      <Slide bottom delay={`${index}00`}>
        <div className="counter-box shadow-box bordered-bottom">
          <h3 style={font_span_numbers_css}>{item.number}</h3>
          <p style={fonts_p_css}>{item.text}</p>
        </div>
      </Slide>
    </Col>
  );

	return (
    <section className="bg-gray bg-with-lines" id={headers[1].link}>
      <Container>
        <h2 style={font_h2_css}>{headers[1].h2About}</h2>
        <Row className="align-items-center">
          {aboutItems}
        </Row>
      </Container>
    </section>
	)
}

export default NumbersScreen;