import { React } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import './price-screen.sass';
import Fade from 'react-reveal/Fade';

function PriceScreen({ priceConditionData, priceListData, headers, fonts_p, fonts_headers }) {
  const fonts_p_css={
    fontFamily: fonts_p[0].font_p_prices,
    fontWeight: fonts_p[1].font_p_prices
  }
  const font_p_prices_notes_css={
    fontFamily: fonts_p[0].font_p_prices_notes,
    fontWeight: fonts_p[1].font_p_prices_notes
  }
  const font_span_price_css={
    fontFamily: fonts_p[0].font_span_price,
    fontWeight: fonts_p[1].font_span_price
  }
  const font_span_price_note_css={
    fontFamily: fonts_p[0].font_span_price_note,
    fontWeight: fonts_p[1].font_span_price_note
  }
  const font_h2_css={
    fontFamily: fonts_headers[0].h2,
    fontWeight: fonts_headers[1].h2
  }
  const font_h3_css={
    fontFamily: fonts_headers[0].h3,
    fontWeight: fonts_headers[1].h3
  }

  const stepItems = priceListData.map((item, index) => {
    return (
      <Col key={item.id} md={6} lg={4}>
        <Fade delay={`${index}00`}>
          <div className="price-box">
            <span className="price-value" style={font_span_price_css}>{item.price}</span>
            <span className="price-unit" style={font_span_price_note_css}> руб. / 
              {item.units === 'metres' && <span style={font_span_price_note_css}> м<sup>2</sup></span>}
              {item.units === 'project' && <span style={font_span_price_note_css}> проект</span>}
            </span>
            <span style={fonts_p_css} className="price-scale">Площадь: <span className="price-scale-value">{item.scale} м<sup>2</sup></span> </span>
            <span style={fonts_p_css} className="price-period">Срок: {item.period}</span>
            <a href="#contacts"><Button size="lg" variant="dark">{item.button_text}</Button></a>
          </div>
        </Fade>
      </Col>
    )
  });

return (
  <>
    <section id={headers[4].link} className="section bg-with-lines">
      <Container>
        <h2 style={font_h2_css}>{headers[4].h2About}</h2>
        <Row>
          {stepItems}
        </Row>
        <Row className="conditions">
          <Col>
            <h3 style={font_h3_css}>{priceConditionData[0].header_1}</h3>
            <div style={font_p_prices_notes_css} dangerouslySetInnerHTML={{__html: priceConditionData[0].price_decrease}} />
          </Col>
          <Col>
            <h3 style={font_h3_css}>{priceConditionData[0].header_2}</h3>
            <div style={font_p_prices_notes_css} dangerouslySetInnerHTML={{__html: priceConditionData[0].price_increase}} />
          </Col>
        </Row>
      </Container>
    </section>
  </>
)
}

export default PriceScreen;