import { React } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ModalFramePortfolio from "./ModalFramePortfolio";
import './portfolio.sass';
import Fade from 'react-reveal/Fade';

function PortfolioScreen({ portfolioData, headers, fonts_headers, fonts_p }) {
  const font_h2_css={
    fontFamily: fonts_headers[0].h2,
    fontWeight: fonts_headers[1].h2
  }

  const portfolioItems = portfolioData.map((item, index) => {
    return (
      <Col md={4} key={item.id} className="p-0">
        <Fade delay={`${index}00`}>
          <ModalFramePortfolio 
            fonts_headers={fonts_headers}
            fonts_p={fonts_p}
            project={item} 
          />
        </Fade>
      </Col>
    )
  });

  return (
    <>
      <section id={headers[6].link}>
        <Container fluid className="position-relative">
          <h2 style={font_h2_css}>{headers[6].h2About}</h2>
          <Row className="align-items-center">
            {portfolioItems}
          </Row>
        </Container>
      </section>
    </>
  )
}

export default PortfolioScreen;