function EducationItems({ data, heightOfMonth, startYear, fonts_p}) {
  return data.map((item) => {
    const { year_end: yearEnd, month_end: monthEnd, year, month } = item;

    if (item.type_of_expirience === 'education') {
      const height = ((yearEnd - year) * 12 - (month - monthEnd)) * heightOfMonth;
      const offsetTop = ((year - startYear) * 12 - (0 - month)) * heightOfMonth;
      const css = {
        height: height + 'px',
        top: offsetTop + 'px',
        fontFamily: fonts_p[0].font_p_expirience,
        fontWeight: fonts_p[1].font_p_expirience
      };
      let classes = "expirience-box";

      if (height <= heightOfMonth * 3) {
        css.left = '35px';
        css.width = '86%';
        classes = "expirience-box expirience-hidden-box"
      }
      return <p style={css} key={item.id.toString()} className={classes}>{item.name_of_expirience}</p>
    }
  });
}

export default EducationItems;
