import React from "react";
import LandingPage from "../pages/landing-page/LandingPage";

class Body extends React.Component {
  render() {
    return (
      <LandingPage />
    )
  }
}

export default Body;