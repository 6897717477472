import { React, useRef } from "react";
import { Row, Col } from 'react-bootstrap';
import './work-steps.sass';
import useOnScreen from '../../../../hooks/useOnScreen';
import Fade from 'react-reveal/Fade';
import { REACT_APP_API_URL } from '../../../../constants';

function WorkStep({ item, fonts_p, fonts_headers }) {
  const elementRef = useRef();
  const isVisible = useOnScreen(elementRef);
  const isEven = item.id % 2 === 0;
  const stepClass = `${isVisible ? 'work-step-box-active' : ''} ${isEven ? 'text-right work-step-box-right' : ''}`;
  const spanClass = `${isEven ? 'circle-span-right' : ''}`;
  const fonts_p_steps_css={
    fontFamily: fonts_p[0].font_p_steps,
    fontWeight: fonts_p[1].font_p_steps
  }
  const font_steps_css={
    fontFamily: fonts_p[0].font_steps,
    fontWeight: fonts_p[1].font_steps
  }
  const font_h3_css={
    fontFamily: fonts_headers[0].h3,
    fontWeight: fonts_headers[1].h3
  }

  return (
    <Row>
      { !isEven && <Col md={6}></Col> }
      <div className={`${stepClass} work-step-box col-md-6 `}>
        <Fade>
          <span className={`${spanClass}`}>
            <img alt="" src={`${REACT_APP_API_URL}/assets/${item.illustration_step.id}?download`} />
          </span>
        </Fade>
        <span style={font_steps_css} className={`${spanClass} circle-span step-number`}>{item.step}</span>
        <Fade bottom>
          <h3 style={font_h3_css}>{item.h2}</h3>
          </Fade>
        <div style={fonts_p_steps_css} dangerouslySetInnerHTML={{__html: item.text}} />
      </div>
      <span ref={elementRef} className="ref-span"></span>
      { isEven && <Col md={6}></Col> }
    </Row>
  );
}

export default WorkStep;
